import React,{useState} from 'react'
import './header.css'
import Logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png';
import { Link } from 'react-scroll';
const Header = () => {
    const mobile = window.innerWidth<=768 ? true : false;
    const [menuOpened,setMenuOpened] = useState(false);

  return (
   <div className="header">
     <img src={Logo} alt="logo" className="logo" />
     {(menuOpened===false && mobile===true) ?(
      <div style={{backgroundColor:'var(--appColor)',padding:'0.5rem'
    ,borderRadius:'5px'}}
      onClick={()=>setMenuOpened(true) }
      ><img src={Bars} alt="bars" 
      style={{width:'1.5rem',height:'1.5rem'}} /></div>
     ):
     <ul className="header-menu">
     <li ><Link onClick={()=>setMenuOpened(false)} spy={true} smooth={true}
     activeClass='active' to='land'>Home</Link></li>
     <li ><Link onClick={()=>setMenuOpened(false)} spy={true} smooth={true} to='programs'>Programs</Link></li>
     <li ><Link onClick={()=>setMenuOpened(false)} spy={true} smooth={true} to='Reasons'>Why us</Link></li>
     <li ><Link onClick={()=>setMenuOpened(false)} spy={true} smooth={true} to='plans'>Plans</Link></li>
     <li ><Link onClick={()=>setMenuOpened(false)} spy={true} smooth={true} 
     spy={true} smooth={true} to='testimonials'>Testimonials</Link></li>
  </ul>
     }
   
   </div>
  )
}

export default Header